import axios from "axios";

export const baseURL = "http://ec2-100-25-4-118.compute-1.amazonaws.com";
// export const baseURL = 'http://localhost:4001';

export const baseUploadURL = baseURL + "/uploads/";

const user = localStorage.otolatrahlatcom ? JSON.parse(localStorage.otolatrahlatcom) : "";

export const HTTP = axios.create({
  baseURL: `${baseURL}/api/`,
  headers: {
    Authorization: `Bearer ${user?.token}`,
    // lang: localStorage.lang || "ar",
  },
});
